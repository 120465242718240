import React from "react";
import { useTranslation } from "react-i18next";
import { ZebecBlackCardImg, ZebecInstantCardImg } from "../../../assets/images";
import { Link } from "react-router-dom";
import { Button } from "../../shared/Button";
import * as Icons from "../../../assets/icons";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="font-ppmori relative">
      <div className="h-full w-full md:min-h-[700px] rounded-[30px] bg-no-repeat bg-cover md:[background-size:100%_700px] zebec-black-hero-bg text-center pt-20 pb-10 px-10 lg:px-20">
        <h2 className="text-4xl font-normal md:text-5xl lg:text-[60px] lg:leading-[60px] text-white">
          {t("zebecBlack:hero_section.h1_1")} <br />
          {t("zebecBlack:hero_section.h1_2")}
        </h2>
        <div className="grid md:grid-cols-2 items-center mt-12 lg:h-[275px] gap-6 max-w-4xl mx-auto">
          <div className="">
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mb-2 hidden md:block">
              {t("zebecBlack:hero_section.fast_private")}
            </p>
            <img
              src={ZebecInstantCardImg}
              className="w-auto max-h-72 mx-auto"
              alt=""
            />
            <Link
              to={"https://app.card.zebec.io/"}
              target="_blank"
              rel="noreferrer noopener"
              className=""
            >
              <Button
                title={t("zebecBlack:hero_section.zebec_silver")}
                className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary m-auto mt-4"
                startIcon={
                  <div
                    className={
                      "w-10 h-10 rounded-full bg-primary grid place-content-center"
                    }
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </Link>
          </div>
          <div className="">
            <p className="text-zebec-primary-light text-[22px] leading-[33px] -tracking-[1%] text-center mb-2 hidden md:block">
              {t("zebecBlack:hero_section.reloadable_limitless")}
            </p>
            <img
              src={ZebecBlackCardImg}
              className=" w-auto max-h-72 mx-auto"
              alt=""
            />
            <Link
              to={"https://black.zebec.io/"}
              target="_blank"
              rel="noreferrer noopener"
              className=""
            >
              <Button
                title={t("zebecBlack:hero_section.zebec_black")}
                className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary m-auto mt-4"
                startIcon={
                  <div
                    className={
                      "w-10 h-10 rounded-full bg-primary grid place-content-center"
                    }
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
