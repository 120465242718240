import React, { useState } from "react";
import {
  GradientCheckedCircleIcon,
  CheveronRightIcon,
} from "../../../assets/icons";
import { EasySetupImage, Line } from "../../../assets/images";
import HowToVideoLoop from "../../layout/HowToVideoLoop";
import { Button } from "../../shared/Button";
import * as Videos from "../../../assets/video/index";
import * as Images from "../../../assets/images/index";

const setup = [
  {
    step: "Step 1",
    desc: "Authenticate via e/mail",
  },
  {
    step: "Step 2",
    desc: "Confirm your card registration by submitting personal details",
  },
  {
    step: "Step 3",
    desc: "Download Mobulum App, connect wallet and load up card with crypto",
  },
  {
    step: "Step 4",
    desc: "Start spending IRL",
  },
];

const ZebecEasySetup = () => {
  const [openVideoLoop, setOpenVideoLoop] = useState(false);

  return (
    <>
      {openVideoLoop ? (
        <HowToVideoLoop
          setOpenVideoLoop={setOpenVideoLoop}
          video={Videos.HowToUseZebecBlackCard}
          thumbnail={Images.ZebecBlackCardHowToThumbnail}
        />
      ) : null}
      <div className="w-full max-w-[1200px] justify-between mx-auto md:flex items-center gap-5 lg:gap-10 my-16 md:my-24">
        <div>
          <img src={EasySetupImage} className="w-[538px] mx-auto" alt="" />
        </div>
        <div className="mt-10 sm:mt-0">
          <h2 className="text-[40px] font-normal leading-[130%] text-zebec-primary">
            A Few Easy Steps
          </h2>
          <div className="mt-10 space-y-10 relative">
            <img
              src={Line}
              alt=""
              className="absolute left-[19px] top-12 h-[120px] z-[-1]"
            />
            <img
              src={Line}
              alt=""
              className="absolute left-[19px] bottom-12 h-[120px] z-[-1]"
            />
            {setup.map((data, index) => {
              return (
                <div className="flex items-center gap-4" key={index}>
                  <div className="w-[40px] h-[40px] rounded-full bg-primary grid place-content-center flex-shrink-0">
                    <GradientCheckedCircleIcon />
                  </div>
                  <div>
                    <h2 className="gradient-text font-normal">{data.step}</h2>
                    <p className="w-auto md:max-w-[329px] text-zebec-primary uppercase">
                      {data.desc}
                    </p>
                    <p className="text-purple-light w-auto md:w-[329px]">
                      {data.subdec}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-wrap gap-3">
            <a
              href="https://black.zebec.io/"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <Button
                title={"Order Zebec Black"}
                className="gradient-button-border text-zebec-primary mt-10 text-sm lg:text-base rounded-full bg-white"
                startIcon={
                  <div
                    className={`w-10 h-10 rounded-full bg-icon-gradient grid place-content-center`}
                  >
                    <CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </a>
            <Button
              title={"Video Tutorial"}
              onClick={() => setOpenVideoLoop(true)}
              className="gradient-button-border text-zebec-primary mt-10 !px-[20px] text-sm lg:text-base rounded-full bg-white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZebecEasySetup;
