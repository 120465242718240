import React from "react";
import {
  PartnerAitechImg,
  PartnerBasenjiImg,
  PartnerBrettImg,
  PartnerManekiImg,
  PartnerMewImg,
  PartnerNeiroImg,
  PartnerSpectreAiImg,
  PartnerSundogImg,
  Token1,
  Token10,
  Token11,
  Token12,
  Token13,
  Token14,
  Token2,
  Token3,
  Token4,
  Token5,
  Token6,
  Token7,
  Token8,
  Token9,
} from "../../assets/images/partner";
import { Button } from "../shared/Button";
import * as Icons from "../../assets/icons";
import Cards from "./Cards";

const WhyPartner = () => {
  const tokensImages = [
    Token1,
    Token2,
    Token3,
    Token4,
    Token5,
    Token6,
    Token7,
    Token8,
    Token9,
    Token10,
    Token11,
    Token12,
    Token13,
    Token14,
  ];
  const cardImages = [
    { img: PartnerSundogImg, blackUrl: "http://sundog.zebec.io/" },
    { img: PartnerAitechImg, blackUrl: "http://aitech.zebec.io/" },
    { img: PartnerManekiImg, blackUrl: "http://maneki.zebec.io/" },
    { img: PartnerNeiroImg, blackUrl: "http://neiro.zebec.io/" },
    { img: PartnerSpectreAiImg, blackUrl: "http://spectre.zebec.io/" },
    { img: PartnerMewImg, blackUrl: "https://mew.zebec.io/" },
    { img: PartnerBrettImg, blackUrl: "http://brett.zebec.io/" },
    { img: PartnerBasenjiImg, blackUrl: "http://basenji.zebec.io/" },
  ];
  return (
    <>
      <div className="web-padding font-ppmori">
        <div className="bg-[#F8F8FF] px-6 py-2 rounded-[30px] mt-12 md:mt-16">
          <div className="flex items-center justify-center gap-4 flex-wrap">
            {tokensImages.map((token, index) => {
              return (
                <img
                  key={index}
                  src={token}
                  alt=""
                  className="w-12 h-12 md:w-[78px] md:h-[78px] rounded-full"
                />
              );
            })}
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-6 lg:gap-10 p-8 md:p-12 bg-[#F8F8FF] rounded-[30px] mt-12 md:mt-16">
          <div className="flex flex-col gap-8">
            <div className="">
              <h3 className="text-[28px] md:text-[32px] lg:text-[40px] leading-[120%] text-[#32285A] mb-3 font-normal">
                Case Report: Meme Dreams Turned into Reality
              </h3>
              <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
                Branded crypto cards, powered by Zebec and Mastercard.
                <br />
                Spend Anywhere. Anytime.
              </p>
            </div>
            <div className="">
              <h3 className="text-[28px] md:text-[32px] lg:text-[40px] leading-[120%] text-[#32285A] mb-3 font-normal">
                Real Utility, Real Fun
              </h3>
              <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
                Bring memes to life! Spendable value in the real world.
              </p>
            </div>
            <div className="">
              <h3 className="text-[28px] md:text-[32px] lg:text-[40px] leading-[120%] text-[#32285A] mb-3 font-normal">
                Memes to Money
              </h3>
              <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
                Fund with Memes, Spend in Fiat Worldwide.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            {cardImages.map((card, index) => {
              return (
                <a
                  href={card.blackUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={index}
                  className=""
                >
                  <img
                    src={card.img}
                    alt=""
                    className="max-w-[280px] w-full h-auto mx-auto rounded-xl"
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="my-12 md:my-16">
          <h3 className="text-[28px] md:text-[32px] lg:text-[40px] leading-[120%] text-[#32285A] mb-4 font-normal">
            Why Partner with Zebec?
          </h3>
          <div className="flex flex-col gap-10">
            <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
              <span className="font-bold">Meme Dreams into Reality:</span> Join
              70+ Projects from blue-chip memes and OGs to hot newcomers across
              all leading networks launched with us.
            </p>
            <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
              <span className="font-bold">Supercharge Engagement:</span> Turn
              engagement into real-world action.
              <br />
              Your Tokens are no longer just for the screen—they’re in your
              pocket, ready to use.
            </p>
            <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
              <span className="font-bold">Boost Your Brand:</span> Zebec’s reach
              and marketing muscle will amplify buzz around your project far
              beyond your native ecosystem and fanbase.
            </p>
            <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
              <span className="font-bold">Attract New Fans:</span> Unlock value
              for holders, and create compelling incentives that pull in fresh
              eyes and wallets.
            </p>
          </div>
        </div>
        <Cards />
        <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-x-6 md:gap-x-8 pt-8 md:pt-12 pb-12 md:pb-16">
          <h3 className="text-[28px] md:text-[32px] lg:text-[40px] leading-[120%] text-[#32285A] mb-4 font-normal">
            Ready to Level Up?
          </h3>
          <div className="">
            <p className="text-lg md:text-[22px] leading-[150%] text-[#32285A]">
              Ready to Partner to Launch Your Custom Card
            </p>
            <a
              href="https://telegram.me/jjortiz15"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <Button
                title="Contact Us"
                className="gradient-button-border text-zebec-primary text-sm lg:text-base rounded-full bg-white mt-6"
                startIcon={
                  <div
                    className={`w-10 h-10 rounded-full bg-icon-gradient grid place-content-center`}
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyPartner;
